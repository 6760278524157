import React, {Component} from 'react';
import './footerStyles.css'
class FooterMain extends Component {
    render() {
        return (
            <div className='dv-footer d-flex justify-content-start pr-3' dir='rtl'>
                <p className='dv-footer-p'>طراحی و توسعه توسط :  <a href='http://rahkargostaran.ir' className='dv-rahkar-color'> راهکارگستران</a> </p>
            </div>
        );
    }
}

export default FooterMain;
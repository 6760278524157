import React, {Component} from 'react';
import './header-styles.css'
import {Navbar , Nav , NavDropdown} from "react-bootstrap";

class Header_top extends Component {

    logOut = () => {
        localStorage.removeItem('brief_tok')
    }
    render() {
        return (
            <Navbar bg="dark" className='navbar-dark bg-dark' expand="lg" dir='rtl'>
                <Navbar.Brand href="#">راهکارگستران</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <NavDropdown id="basic-nav-dropdown" className='text-right dv-dropdown' title={<i className='fa fa-user'/>}>
                            <NavDropdown.Item href="/login" onClick={this.logOut}>خروج از داشبورد</NavDropdown.Item>
                            {/*<NavDropdown.Item href="/password">تغییر رمز</NavDropdown.Item>*/}
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

export default Header_top;
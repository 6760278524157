import React from "react";
import "./Loader.styles.css"

const Loader = (props) => (
    <div id="loader" className={props.show ? '' : "hidden"} >
        <div className="lds-ellipsis">
            <div/>
            <div/>
            <div/>
            <div/>
        </div>
    </div>
);

export default Loader;
import React, {Component} from 'react';
import './login-styles.css';
import Logo from '../../../assets/images/Logo.png';
import {toast} from "react-toastify";
import {getData} from "../../../assets/scripts/GeneralFunctions";
import {MAIN_URL} from "../../../assets/scripts/GeneralVariables";
import {Modal} from "react-bootstrap";
import Countdown from 'react-countdown';
import Users from "../dashboard/child/main_content/users/Users";

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username : '',
            password : '',
            verify_username_modal: false,
            forget_password_username_modal: false,
            forget_password_verify_modal: false,
            forget_password_repassword_modal: false,
            forget_password_data: {
                phone: "",
                verify_code: "",
                password: "",
                re_password: "",
                forget_pass_token: ""
            },
            verify_code: "",
        }
    }
    usernameHandler = (e) => {
        this.setState({
            username : e.target.value
        })
    }
    passwordHandler = (e) => {
        this.setState({
            password : e.target.value
        })
    }
    // forget password open modal
    frgtPassMobModalHandler = status => {
        this.setState({
            forget_password_username_modal: status
        })
    };
    // Add phone number in forget password modal
    forgetPassChangeHandler = (event) => {
        let {value , name} = event.target;

        this.setState(prevState => ({
            forget_password_data: {
                ...prevState.forget_password_data,
                [name]: value
            }
        }))
    };
    // Send phone number for get verify code
    sendForgetPasswordusername = async () => {
        let {phone} = this.state.forget_password_data;

        let numRegex = /[0-9]{11}|[۰-۹]{11}/;
        if (phone === "" || !numRegex.test(phone)) {
            toast.error("شماره موبایل نامعتبر");
            return false;
        }

        let data = await getData(MAIN_URL, 'password/forgot-password', 'post', {mobile : phone});

        if (data) {
            // console.log(data)
            toast.success("کد تایید به شماره موبایل شما ارسال شد");
            this.setState({
                forget_password_username_modal: false,
                forget_password_verify_modal: true
            })
        }
    };
    // verify modal
    frgtPassVrfyModalHandler = status => {
        this.setState({
            forget_password_verify_modal: status
        })
    };
    // Send verify Code
    sendForgetPassVerify = async () => {
        let {verify_code , phone} = this.state.forget_password_data;

        if (verify_code.trim() === "") {
            toast.error("کد تایید نامعتبر");
            return false;
        }

        let data = await getData(MAIN_URL, 'password/verify-code', 'post', {mobile : phone, verification_code: verify_code});
        // console.log(data);
        if (data) {
            toast.success("کد تایید موفقیت آمیز بود");
            this.setState(prevState => ({
                forget_password_verify_modal: false,
                forget_password_repassword_modal: true,
                forget_password_data: {
                    ...prevState.forget_password_data,
                    forget_pass_token: data.token
                }

            }))
        }
    };
    // set new password modal handler
    frgtPassRePassModalHandler = status => {
        this.setState({
            forget_password_repassword_modal: status
        })
    };
    // send new password value set
    sendForgetPassRepass = async () => {
        let {password, re_password, forget_pass_token} = this.state.forget_password_data;


        if (password.trim() === "" || re_password.trim() === "") {
            toast.error("رمز عبور و یا تکرار آن نمی تواند خالی باشد");
            return false;
        } else if (password !== re_password) {
            toast.error("رمز عبور با تکرار آن مطابقت ندارد");
            return false;
        } else {
            // console.log(password , re_password , forget_pass_token);
            let data = getData(MAIN_URL, 'password/change-password', 'post', {
                password: password,
                password_confirmation: re_password
            }, true, true, true, forget_pass_token);
            // console.log(this.state.login_data.password);
            if (data) {
                toast.success("رمز عبور شما با موفقیت تغییر یافت");
                this.setState({
                    forget_password_repassword_modal: false,
                    username: "",
                    password: "",
                })
            }
        }
    };
// Login handler
    loginHandler = async(e)=>{
        e.preventDefault()
        const {username , password} = this.state

        let numRegex = /[0-9]{11}|[۰-۹]{11}/;

        if (username === "" || !numRegex.test(username) || username.length !== 11) {
            toast.error("شماره موبایل نامعتبر");
            return false;
        }

        // console.log(username , password)

        let items = await getData(MAIN_URL, `login`, 'post', {mobile : username , password : password}, false , false);

        if (items) {
            // console.log(items)
            if (items.status === 200) {
                toast.success("ورود با موفقیت انجام شد");
                localStorage.setItem("brief_tok", items.token);
                window.location.replace("/users")
            } else {
                toast.success("خطای ناخواسته ای پیش آمد");
            }
        }
    }







    // countDownRender = ({minutes, seconds, completed}) => {
    //     if (completed) {
    //         this.setState({
    //             end_count_down: true
    //         });
    //         return <span/>;
    //     } else {
    //         return <span>{("0" + minutes).slice(-2)}:{("0" + seconds).slice(-2)}</span>;
    //     }
    // };

    // resendVerifyCode = async () => {
    //     const {mobile} = this.state.register_data;
    //
    //     let data = await getData(MAIN_URL, 'send-verification', 'post', {mobile});
    //     if (data) {
    //         this.setState({
    //             count_down_time: parseInt(data.time) * 1000,
    //             end_count_down: false,
    //         });
    //     }
    // };

    // sendVerifyCode = async () => {
    //     const {verify_code, register_data} = this.state;
    //
    //     if (verify_code.trim() === "") {
    //         toast.error("کد تایید نامعتبر");
    //         return false;
    //     }
    //
    //     let data = await getData(MAIN_URL, 'verify-mobile', 'post', {
    //         mobile: register_data.mobile,
    //         verification_code: verify_code
    //     });
    //
    //     if (data) {
    //         //  toast.success("ثبت نام شما با موفقیت انجام شد")
    //         this.setState({
    //             verify_code: "",
    //             register_data: {
    //                 mobile: "",
    //                 password: "",
    //                 re_password: ""
    //             },
    //             verify_mobile_modal: false,
    //             count_down_time: 0,
    //             end_count_down: true,
    //             is_login_card: true,
    //         })
    //     }
    // };

    // changeHandler = (event) => {
    //     const {name, value} = event.target;
    //
    //     this.setState({
    //         [name]: value
    //     })
    // };

    // verifyusernameModalHandler = status => {
    //     this.setState({
    //         verify_username_modal: status
    //     })
    // };





    render() {
        const {username , password , forget_password_username_modal,
            forget_password_verify_modal,forget_password_repassword_modal,
            forget_password_data,
        } = this.state

        return (
            <div className='bg-login-form' dir='rtl'>
                <div className='row justify-content-center'>
                    <div className='col-12 col-md-10 pr-3 pr-md-5 pl-3 pl-md-5'>
                       <div className='container'>
                           <div className='dv-form'>
                               <div className='container'>
                                   <div className='row'>
                                       <div className='col-12'>
                                           <div className='row pr-3 pl-3 pr-md-5 pl-md-5'>
                                               <div className='col-12 col-md-6 d-flex align-items-center'>
                                                   <div className='row'>
                                                       <div className='col-12 d-flex justify-content-center mb-5'>
                                                           <img src={Logo} className='dv-logo-image' alt='راهکارگستران'/>
                                                       </div>
                                                       <div className='col-12 mb-3 d-flex justify-content-center'>
                                                           <h4 className='dv-h4'>پنل مدیریت ارزیابی های اولیه</h4>
                                                       </div>
                                                   </div>
                                               </div>

                                               <div className='col-12 col-md-6 dv-border-left text-right d-flex align-items-center'>
                                                   <form onSubmit={this.loginHandler} className='dv-login-form'>
                                                       <label htmlFor='username' className='mb-4 w-100'>
                                                           <span>نام کاربری</span>
                                                           <input id='username' className='w-100 mt-2' type='number' name='tel' onChange={this.usernameHandler}
                                                                  value={username} placeholder='نام کاربری(شماره تماس) خود را وارد کنید'/>
                                                       </label>
                                                       <label htmlFor='password' className='mb-3 w-100'>
                                                           <span>رمز عبور</span>
                                                           <input id='password' onChange={this.passwordHandler}
                                                                  value={password} className='w-100 mt-2' type='password' name='password'
                                                                  placeholder='رمز عبور خود را وارد کنید'/>
                                                       </label>
                                                       <p onClick={() => this.frgtPassMobModalHandler(true)}
                                                          className="d-block text-m-light-gray text-right cursor-pointer mt-3 mb-3">فراموشی
                                                           رمز
                                                           عبور</p>
                                                       <button type='submit' className='dv-login-btn'>ورود</button>
                                                   </form>
                                               </div>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                    </div>
                </div>

                {/*Add Phone Number*/}
                <Modal
                    show={forget_password_username_modal}
                    onHide={() => this.frgtPassMobModalHandler(false)}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="custom-size-modal"
                >
                    <Modal.Body className="text-center">
                        <p className="text-m-light-gray text-right w-100">فراموشی رمز عبور</p>
                        <div className="w-100 text-right mt-5">
                            <label htmlFor="username_forget_pass" className="text-m-light-gray">شماره موبایل خودرا وارد
                                کنید </label>
                            <input type="number" name="phone" className="simple-input text-center w-100"
                                   id="username_forget_pass"
                                   onChange={this.forgetPassChangeHandler}
                                   value={forget_password_data.phone}/>
                        </div>

                        <div className="text-left w-100 mt-4">
                            <button className="btn btn-main-success ml-2"
                                    onClick={this.sendForgetPasswordusername}>دریافت پیامک کد
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
                {/*****************************   Verify Code    ***********************************/}
                <Modal
                    show={forget_password_verify_modal}
                    onHide={() => this.frgtPassVrfyModalHandler(false)}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="custom-size-modal"
                >
                    <Modal.Body className="text-center">
                        <p className="text-m-light-gray text-right w-100">فراموشی رمز عبور</p>
                        <div className="w-100 d-flex justify-content-center flex-column mt-5">
                            <label htmlFor="verify_code_forget_pass" className="text-m-light-gray">کد دریافتی را وارد
                                نمایید </label>
                            <input type="text" name="verify_code" className="simple-input text-center w-100"
                                   id="verify_code_forget_pass"
                                   onChange={this.forgetPassChangeHandler}
                                   value={forget_password_data.verify_code}/>
                        </div>

                        <div className="text-left w-100 mt-4">
                            <button className="btn btn-main-success ml-2"
                                    onClick={this.sendForgetPassVerify}>تایید
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
                {/****************************** Set new password **********************************/}
                <Modal
                    show={forget_password_repassword_modal}
                    onHide={() => this.frgtPassRePassModalHandler(false)}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="custom-size-modal"
                >
                    <Modal.Body className="text-center">
                        <p className="text-m-light-gray text-right w-100">فراموشی رمز عبور</p>
                        <div className="w-100 d-flex justify-content-center flex-column mt-5">
                            <label htmlFor="password_forget_pass" className="text-m-light-gray">رمز جدید </label>
                            <input type="password" name="password" className="simple-input text-center w-100"
                                   id="password_forget_pass"
                                   onChange={this.forgetPassChangeHandler}
                                   value={forget_password_data.password}/>

                            <label htmlFor="password_forget_pass mt-3" className="text-m-light-gray">تکرار رمز
                                جدید </label>
                            <input type="password" name="re_password" className="simple-input text-center w-100"
                                   id="re_password_forget_pass"
                                   onChange={this.forgetPassChangeHandler}
                                   value={forget_password_data.re_password}/>
                        </div>

                        <div className="text-left w-100 mt-4">
                            <button className="btn btn-main-success ml-2"
                                    onClick={this.sendForgetPassRepass}>تایید
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>


            </div>
        );
    }
}

export default Login;
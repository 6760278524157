import React, {Component} from 'react';
import './right_sidebar_styles.css';
import {Link} from "react-router-dom";

class RightSidebar extends Component {
    render() {
        // console.log(this.props.to)
        // let isActive = this.props.location.href === this.props.to;
        // let className = isActive ? 'active' : '';
        return (
            <div className="nav-container">
                <ul className="dv-nav">
                    <li className={this.props.users ? 'active' : ''}>
                        <Link to={'/users'}>
                            <span className="fa fa-users"/>
                            <span className="text">کاربران</span>
                        </Link>
                    </li>
                    <li className={this.props.project ? 'active' : ''}>
                        <Link to={'/projects'}>
                            <span className="fa fa-tasks"/>
                            <span className="text">پروژه ها</span>
                        </Link>
                    </li>
                </ul>
            </div>
        )
    }
}

export default RightSidebar;
import React, {Component} from 'react';
import './users-styles.css'
import Header_top from "../../header/Header_top";
import RightSidebar from "../../right_sidebar/RightSidebar";
import FooterMain from "../../footer/FooterMain";
import {getData , editedItems} from "../../../../../../assets/scripts/GeneralFunctions";
import {MAIN_URL} from "../../../../../../assets/scripts/GeneralVariables";
import Loader from "../../../../Loader/Loader";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";

class Users extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search_value: '',
            users: [],
            scrollTop: 0,
            isUsersLoader: false
        }
        this.myRef = React.createRef()
    }

    async componentDidMount() {
        let items = await getData(MAIN_URL, `users`, 'get', {limit: 20, offset: 0}, true, false);

        if (items) {
            // console.log(items)
            this.setState({
                users: items.users,
                isAllProductDataLoaded: false,
            })
        }
    }

    inputHandler = async (e) => {
        this.setState({
            search_value: e.target.value
        })
        if (e.target.value) {
            let items = await getData(MAIN_URL, `users`, 'get', {
                search: e.target.value
            }, true, true);

            if (items) {
                // console.log("After Search : ", items)
                this.setState({users: items.users})
            }
            if (items.users.length === 0) {
                toast.warning('آیتمی یافت نشد')
                return false
            }
        }


    }

    searchInputHandler = async (e) => {
        e.preventDefault()
        this.setState({isUsersLoader: true})
        let items = await getData(MAIN_URL, `users`, 'get', {
            search: this.state.search_value
        }, true, false);

        if (items) {
            this.setState({users: items.users , isUsersLoader: false})
        }
    }

    changeStatusNot = async (id)=>{
        this.setState({isUsersLoader: true})
        let items = await getData(MAIN_URL, `users/change-status/${id}`, 'get', {}, true, true);
        if (items) {
            // console.log(items)
            let user = items.users;
            this.setState(prevState => ({isUsersLoader: false , users : editedItems(prevState.users, user)}))
            // window.location.reload()
        }
    }

    changeStatus = async (id)=>{
        this.setState({isUsersLoader: true})
        let items = await getData(MAIN_URL, `users/change-status/${id}`, 'get', {}, true, true);
        if (items) {
            let user = items.users;
            this.setState({isUsersLoader: false , users : this.state.users.map(el => el.id === id ? el = user  : el)})
            // window.location.reload()
        }
    }

    // Lazy Load
    getDataOnScrolled = async (obj) => {

        let offset = 0;
        this.setState({
            isAllProductDataLoaded: true
        });

        if (obj.target.offsetHeight + parseInt(obj.target.scrollTop.toFixed(0)) >= obj.target.scrollHeight) {
            if (this.state.users.length % 20 === 0 && this.state.isAllProductDataLoaded === true) {
                this.setState({isUsersLoader: true});
                let moreData = await getData(MAIN_URL, 'users', 'get',
                    {limit: 20, offset: offset + this.state.users.length}, true, false);
                if (moreData) {
                    this.setState(prevState => ({
                        items: {
                            ...prevState.items, ...moreData,
                            users: this.state.users.concat(moreData.users),
                        },
                        users: this.state.users.concat(moreData.users),
                        isUsersLoader: false,
                    }));

                    // console.log(moreData.users.length);

                    if (moreData.users.length < 20) {
                        this.setState({isAllProductDataLoaded: false})
                    }
                }
            }

        }
    };

    render() {
        // console.log(this.state.users)
        return (
            <>
                <Header_top/>
                <div className='d-flex rtl dv-main-content-size'>
                    <RightSidebar users/>
                    {/*<div className="nav-container">*/}
                    {/*    <ul className="dv-nav">*/}
                    {/*        <li className='active'>*/}
                    {/*            <Link to={'/users'}>*/}
                    {/*                <span className="fa fa-users"/>*/}
                    {/*                <span className="text">کاربران</span>*/}
                    {/*            </Link>*/}
                    {/*        </li>*/}
                    {/*        <li>*/}
                    {/*            <Link to={'/projects'}>*/}
                    {/*                <span className="fa fa-tasks"/>*/}
                    {/*                <span className="text">پروژه ها</span>*/}
                    {/*            </Link>*/}
                    {/*        </li>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}

                    <div className='dv-main-content dv-scroll'
                         ref={this.myRef}
                         onScroll={this.getDataOnScrolled}>
                        <div className='container-fluid dv-lazyload'>
                            <div className='row'>
                                <div
                                    className='col-12 d-flex flex-column flex-md-row align-items-center dv-top-menu-in-page'>
                                    <h4 className='mb-3 mb-md-0'>کاربران</h4>
                                    {/*Search Form*/}
                                    <div className="example mr-auto">
                                        <input type='number' value={this.state.search_value}
                                               onChange={this.inputHandler} placeholder="شماره تلفن را وارد فرمایید"/>
                                        <button onClick={this.searchInputHandler}><i className='fa fa-search'/></button>
                                    </div>
                                </div>
                                <div className='col-12 mb-5 mt-5'>
                                    <div className="table-responsive">
                                        <table className="table dv-custom-table">
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>شماره تماس</th>
                                                <th>وضیعت</th>
                                                <th>تنظیمات</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.users.map((item, index) => (
                                                    <tr key={index * index * index}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{item.mobile}</td>
                                                        {
                                                            item.status == true || item.status == 1 ?
                                                                <td className='text-success'>تایید شده</td> :
                                                                <td className='text-danger'>تایید نشده</td>
                                                        }
                                                        {
                                                            item.status == true || item.status == 1 ?
                                                                <td>
                                                                    <button onClick={()=>this.changeStatusNot(item.id)} className='btn btn-success'>تغییر وضعیت به
                                                                        تایید نشده
                                                                    </button>
                                                                </td> :
                                                                <td>
                                                                    <button onClick={()=>this.changeStatus(item.id)} className='btn btn-info'>تغییر وضعیت به
                                                                        تایید شده
                                                                    </button>
                                                                </td>
                                                        }
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <FooterMain/>
                    </div>
                    {
                        this.state.isUsersLoader ?
                            <Loader show /> : ''
                    }
                </div>
            </>
        );
    }
}

export default Users;
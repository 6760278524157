import React, {Component} from 'react';
import './password-styles.css'
import Header_top from "../../header/Header_top";
import RightSidebar from "../../right_sidebar/RightSidebar";
import FooterMain from "../../footer/FooterMain";

class ChangePassword extends Component {
    render() {
        return (
            <>
                <Header_top/>
                <div className='d-flex position-relative rtl'>
                    <RightSidebar/>
                    <div className='dv-main-content'>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-12 justify-content-center'>
                                    <h1>Change Password</h1>
                                </div>
                            </div>
                        </div>
                        <FooterMain/>
                    </div>
                </div>
            </>
        );
    }
}

export default ChangePassword;
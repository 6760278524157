import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/styles/bootstrap.css';
import './assets/styles/fontawesome-all.min.css';

ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
          <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              pauseOnVisibilityChange
              draggable
              pauseOnHover
          />
          <App />
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

import React, {Component} from 'react';
import './projects-styles.css';
import Header_top from "../../header/Header_top";
import RightSidebar from "../../right_sidebar/RightSidebar";
import FooterMain from "../../footer/FooterMain";
import {getData, editedItems} from "../../../../../../assets/scripts/GeneralFunctions";
import {MAIN_URL} from "../../../../../../assets/scripts/GeneralVariables";
import Loader from "../../../../Loader/Loader";
import {toast} from "react-toastify";
import {NavDropdown} from "react-bootstrap";
import {Multiselect} from 'multiselect-react-dropdown';
import {Link} from "react-router-dom";

class projects extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search_value: '',
            projects: [],
            scrollTop: 0,
            isprojectsLoader: false,
            company_topic: [{name: 'شرکتی و سازمانی', id: 1}, {name: 'استارت آپ', id: 2}, {
                name: 'فروش کالا و خدمات',
                id: 3
            }, {name: 'محتوا محور', id: 4}, {name: 'نرم افزار تحت وب', id: 5}, {name: 'سایر', id: 6}],
            implementation_platform: [
                {name: 'اپلیکیشن ویندوز', id: 1},
                {name: 'اپلیکیشن آی او اس', id: 2},
                {name: 'اپلیکیشن اندروید', id: 3},
                {name: 'تحت وب', id: 4}],
            web_site_language: [{name: 'فارسی', id: 1}, {name: 'انگلیسی', id: 2}, {name: 'عربی', id: 3}, {
                name: 'سایر',
                id: 4
            }],
            data : {
                type_date : 'جدیدترین ها',
                type_date_send : 'latest',
            },
            type_date : 'جدیدترین ها',
            type_date_send : 'latest',
            implementation_platform_state : [],
            company_topic_list_state : [],
            web_site_language_state : [],
        }
        this.myRef2 = React.createRef()
    }

    async componentDidMount() {
        let items = await getData(MAIN_URL, `projects`, 'get', {limit: 5 , offset: 0 , sort : this.state.type_date_send}, true, false);

        if (items) {

            // console.log(items)
            this.setState({
                projects: items.projects,
                isAllProductDataLoaded: false,
            })
        }
    }

    inputHandler = async (e) => {
        let {data} = this.state
        this.setState({
            search_value: e.target.value
        })
        if (e.target.value) {
            let items = await getData(MAIN_URL, `projects`, 'get', {
                search: e.target.value ,
                sort : this.state.type_date_send , limit : 5 , offset : 0,
                company_topic: JSON.stringify(this.state.company_topic_list_state),
                implementation_platform: JSON.stringify(this.state.implementation_platform_state),
                web_site_language: JSON.stringify(this.state.web_site_language_state)
            }, true, false);

            if (items) {
                // console.log("After Search : ", items)
                this.setState({projects: items.projects})
            }
        }


    }

    searchInputHandler = async (e) => {
        e.preventDefault()
        this.setState({isprojectsLoader : true})

        let items = await getData(MAIN_URL, `projects`, 'get', {
            search: this.state.search_value,
            sort : this.state.type_date_send , limit : 5 , offset : 0,
            company_topic: JSON.stringify(this.state.company_topic_list_state),
            implementation_platform: JSON.stringify(this.state.implementation_platform_state),
            web_site_language: JSON.stringify(this.state.web_site_language_state)
        }, true, false);

        if (items) {
            this.setState({projects: items.projects , isprojectsLoader : false})
        }
        if (items.projects.length === 0) {
            toast.warning('آیتمی یافت نشد')
            return false
        }
    }


    // fillter company_topic
    onSelect_company_topic = async (selectedList) =>{
        this.setState({isprojectsLoader : true})
        // console.log(selectedList);
        let company_topic_list = selectedList.map((row)=>(row.name));
        this.setState({company_topic_list_state : company_topic_list})
        // console.log(company_topic_list);
        let items = await getData(MAIN_URL, `projects`, 'get', {

            search: this.state.search_value,
            sort : this.state.type_date_send , limit : 5 , offset : 0,
            company_topic: JSON.stringify(company_topic_list),
            implementation_platform: JSON.stringify(this.state.implementation_platform_state),
            web_site_language: JSON.stringify(this.state.web_site_language_state)
        }, true, false);

        if (items) {
            this.setState({projects: items.projects , isprojectsLoader : false})
        }
    }
    onRemove_company_topic = async (selectedList) =>{
        // console.log(selectedList)
        this.setState({isprojectsLoader : true})
        // console.log(selectedList);
        let company_topic_list = selectedList.map((row)=>(row.name));
        // console.log(company_topic_list);
        let items = await getData(MAIN_URL, `projects`, 'get', {
            search: this.state.search_value,
            implementation_platform: JSON.stringify(this.state.implementation_platform_state),
            web_site_language: JSON.stringify(this.state.web_site_language_state),
            company_topic: JSON.stringify(company_topic_list),
            limit: 5 , offset: 0 ,sort : this.state.type_date_send
        }, true, false);

        if (items) {
            this.setState({projects: items.projects , isprojectsLoader : false})
        }
    }

    // fillter implementation_platform
    onSelect_implementation_platform = async (selectedList) =>{
        this.setState({isprojectsLoader : true})
        let implementation_platform = selectedList.map((row)=>(row.name));
        this.setState({implementation_platform_state : implementation_platform})
        let items = await getData(MAIN_URL, `projects`, 'get', {
            search: this.state.search_value,
            sort : this.state.type_date_send , limit : 5 , offset : 0,
            company_topic: JSON.stringify(this.state.company_topic_list_state),
            web_site_language: JSON.stringify(this.state.web_site_language_state),
            implementation_platform: JSON.stringify(implementation_platform),
        }, true, false);

        if (items) {
            this.setState({projects: items.projects , isprojectsLoader : false})
        }
    }
    onRemove_implementation_platform = async (selectedList) =>{
        this.setState({isprojectsLoader : true})
        let implementation_platform = selectedList.map((row)=>(row.name));
        this.setState({implementation_platform_state : implementation_platform})
        let items = await getData(MAIN_URL, `projects`, 'get', {
            company_topic: JSON.stringify(this.state.company_topic_list_state),
            search: this.state.search_value,
            sort : this.state.type_date_send , limit : 5 , offset : 0,
            implementation_platform: JSON.stringify(implementation_platform),
            web_site_language: JSON.stringify(this.state.web_site_language_state)
        }, true, false);

        if (items) {
            this.setState({projects: items.projects , isprojectsLoader : false})
        }
    }

    // fillter web_site_language
    onSelect_web_site_language = async (selectedList) =>{
        this.setState({isprojectsLoader : true})
        let web_site_language = selectedList.map((row)=>(row.name));
        this.setState({web_site_language_state : web_site_language})
        let items = await getData(MAIN_URL, `projects`, 'get', {
            web_site_language: JSON.stringify(web_site_language),
            search: this.state.search_value,
            sort : this.state.type_date_send , limit : 5 , offset : 0,
            company_topic: JSON.stringify(this.state.company_topic_list_state),
            implementation_platform: JSON.stringify(this.state.implementation_platform_state),
        }, true, false);

        if (items) {
            this.setState({projects: items.projects , isprojectsLoader : false})
        }
    }
    onRemove_web_site_language = async (selectedList) =>{
        this.setState({isprojectsLoader : true})
        let web_site_language = selectedList.map((row)=>(row.name));
        this.setState({web_site_language_state : web_site_language})
        // console.log(web_site_language);
        let items = await getData(MAIN_URL, `projects`, 'get', {
            web_site_language: JSON.stringify(web_site_language),
            search: this.state.search_value,
            sort : this.state.type_date_send , limit : 5 , offset : 0,
            company_topic: JSON.stringify(this.state.company_topic_list_state),
            implementation_platform: JSON.stringify(this.state.implementation_platform_state),
        }, true, false);

        if (items) {
            this.setState({projects: items.projects , isprojectsLoader : false})
        }
    }


    // Lazy Load
    getDataOnScrolledProjects = async (obj) => {
        // console.log(obj.target.offsetHeight , parseInt(obj.target.scrollTop.toFixed(0)) , obj.target.scrollHeight)
        let offset = 0;
        this.setState({
            isAllProductDataLoaded: true
        });

        if (obj.target.offsetHeight + parseInt(obj.target.scrollTop.toFixed(0)) >= obj.target.scrollHeight) {

            if (this.state.projects.length % 5 === 0 && this.state.isAllProductDataLoaded === true) {
                this.setState({isprojectsLoader: true});
                let moreData = await getData(MAIN_URL, 'projects', 'get',
                    {limit: 5, offset: offset + this.state.projects.length,
                        search: this.state.search_value, sort : this.state.type_date_send ,
                        company_topic: JSON.stringify(this.state.company_topic_list_state),
                        implementation_platform: JSON.stringify(this.state.implementation_platform_state),
                        web_site_language: JSON.stringify(this.state.web_site_language_state)
                    }, true, false);
                if (moreData) {
                    // console.log(moreData)
                    this.setState(prevState => ({
                        items: {
                            ...prevState.items, ...moreData,
                            projects: this.state.projects.concat(moreData.projects),
                        },
                        projects: this.state.projects.concat(moreData.projects),
                        isprojectsLoader: false,
                    }));

                    // console.log(moreData.projects.length);

                    if (moreData.projects.length < 5) {
                        this.setState({isAllProductDataLoaded: false})
                    }
                }
            }

        }
    };

    typeNewestHandler = async () =>{
        this.setState({isprojectsLoader : true , type_date : 'جدیدترین ها' , type_date_send : 'latest'})


        let items = await getData(MAIN_URL, `projects`, 'get', {
            search: this.state.search_value,
            sort : 'latest' , limit : 5 , offset : 0,
            company_topic: JSON.stringify(this.state.company_topic_list_state),
            implementation_platform: JSON.stringify(this.state.implementation_platform_state),
            web_site_language: JSON.stringify(this.state.web_site_language_state)
        }, true, false);

        if (items) {

            // console.log(items)
            this.setState({
                projects: items.projects,
                isAllProductDataLoaded: false,
            })
        }
    }

    typeOldestHandler = async () =>{
        this.setState({isprojectsLoader : true , type_date : 'قدیمی ترین ها' , type_date_send : 'oldest'})


        let items = await getData(MAIN_URL, `projects`, 'get', {
            search: this.state.search_value,
            sort : 'oldest' , limit : 5 , offset : 0,
            company_topic: JSON.stringify(this.state.company_topic_list_state),
            implementation_platform: JSON.stringify(this.state.implementation_platform_state),
            web_site_language: JSON.stringify(this.state.web_site_language_state)
        }, true, false);

        if (items) {

            // console.log(items)
            this.setState({
                projects: items.projects,
                isAllProductDataLoaded: false,
            })
        }
    }

    render() {
        return (
            <>
                <Header_top/>
                <div className='d-flex rtl dv-main-content-size'>
                    <RightSidebar project/>
                    {/*<div className="nav-container">*/}
                    {/*    <ul className="dv-nav">*/}
                    {/*        <li>*/}
                    {/*            <Link to={'/users'}>*/}
                    {/*                <span className="fa fa-users"/>*/}
                    {/*                <span className="text">کاربران</span>*/}
                    {/*            </Link>*/}
                    {/*        </li>*/}
                    {/*        <li className='active'>*/}
                    {/*            <Link to={'/projects'}>*/}
                    {/*                <span className="fa fa-tasks"/>*/}
                    {/*                <span className="text">پروژه ها</span>*/}
                    {/*            </Link>*/}
                    {/*        </li>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}

                    <div className='dv-main-content dv-scroll'
                         ref={this.myRef2}
                         onScroll={this.getDataOnScrolledProjects}>
                        <div className='container-fluid dv-top-menu-in-page'>
                            <div className='row'>
                                <div
                                    className='col-12 col-sm-6 d-flex flex-column flex-md-row align-items-center mb-4'>
                                    <h4 className='mb-3 mb-md-0'>پروژه ها</h4>
                                </div>
                                <div
                                    className='col-12 col-sm-6 d-flex flex-column flex-md-row align-items-center mb-4'>
                                    {/*Search Form*/}
                                    <div className="example mr-auto">
                                        <input type='text' value={this.state.search_value}
                                               onChange={this.inputHandler}
                                               placeholder="عبارت مورد نظر را وارد فرمایید"/>
                                        <button onClick={this.searchInputHandler}><i className='fa fa-search'/>
                                        </button>
                                    </div>
                                </div>
                                <div className='col-6 col-sm-4 col-md-3 mb-3 mb-md-0'>
                                    {/*موضوع*/}
                                    <Multiselect
                                        options={this.state.company_topic} // Options to display in the dropdown
                                        selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                        onSelect={this.onSelect_company_topic} // Function will trigger on select event
                                        onRemove={this.onRemove_company_topic} // Function will trigger on remove event
                                        placeholder='موضوع فعالیت'
                                        closeIcon="close"
                                        showCheckbox={true}
                                        displayValue="name" // Property name to display in the dropdown options
                                    />
                                </div>
                                <div className='col-6 col-sm-4 col-md-3 mb-3 mb-md-0'>
                                    {/*بستر    */}
                                    <Multiselect
                                        options={this.state.implementation_platform} // Options to display in the dropdown
                                        selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                        onSelect={this.onSelect_implementation_platform} // Function will trigger on select event
                                        onRemove={this.onRemove_implementation_platform} // Function will trigger on remove event
                                        placeholder='بستر های پیاده سازی'
                                        closeIcon="close"
                                        showCheckbox={true}
                                        displayValue="name" // Property name to display in the dropdown options
                                    />
                                </div>
                                <div className='col-6 col-sm-4 col-md-3 mb-3 mb-md-0'>
                                    {/*زبان*/}
                                    <Multiselect
                                        options={this.state.web_site_language} // Options to display in the dropdown
                                        selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                        onSelect={this.onSelect_web_site_language} // Function will trigger on select event
                                        onRemove={this.onRemove_web_site_language} // Function will trigger on remove event
                                        placeholder='زبان پروژه'
                                        closeIcon="close"
                                        showCheckbox={true}
                                        displayValue="name" // Property name to display in the dropdown options
                                    />
                                </div>
                                <div className='col-6 col-sm-4 col-md-3 mb-3 mb-md-0'>
                                    {/*جدیدترین*/}
                                    <NavDropdown id="basic-nav-dropdown" className='text-right dv-dropdown-projects'
                                                 title={this.state.type_date}>
                                        <NavDropdown.Item onClick={this.typeNewestHandler}>جدیدترین ها</NavDropdown.Item>
                                        <NavDropdown.Item onClick={this.typeOldestHandler}>قدیمی ترین ها</NavDropdown.Item>
                                    </NavDropdown>
                                </div>

                            </div>
                        </div>


                        <div className='container-fluid dv-lazyload mb-5'>
                            <div className='row'>
                                {/*Cards*/}
                                {
                                    this.state.projects.map((item,index)=>(
                                        <div className='col-12 mt-5'>
                                            <div className='card'>
                                                <div className="card-body text-right dv-custom-card-body">
                                                    <div className="row">
                                                        <div className="col-12 col-md-6">
                                                            <div className='mb-3'>
                                                                <h6 className='mb-3'>نام و نام خانوادگی :</h6>
                                                                <p className='pr-4'>{item.customer_name}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className='mb-3'>
                                                                <h6 className='mb-3'>شماره تماس :</h6>
                                                                <p className='pr-4'>{item.mobile}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className='mb-3'>
                                                                <h6 className='mb-3'>نام مجموعه :</h6>
                                                                <p className='pr-4'>{item.company_name}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className='mb-3'>
                                                                <h6 className='mb-3'>موضوع پروژه :</h6>
                                                                <p className='pr-4'>{item.company_topic}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className='mb-3'>
                                                                <h6 className='mb-3'>بسترهای پیاده سازی :</h6>
                                                                <p className='pr-4'>{item.implementation_platform.length === 1 ? item.implementation_platform : `${item.implementation_platform} ${item.implementation_platform.lastIndexOf(item.implementation_platform) ? '' : ' - '}`}</p>
                                                                {/*{item.implementation_platform.map((row , index)=>(*/}
                                                                {/*    <>*/}
                                                                {/*        <p key={index}>{item.implementation_platform.length === 1 ? row : `${row} ${item.implementation_platform.lastIndexOf(row)? '' : ' - '}` }</p>*/}
                                                                {/*    </>*/}
                                                                {/*))}*/}
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className='mb-3'>
                                                                <h6 className='mb-3'>وضعیت سفارش لوگو :</h6>
                                                                <p className='pr-4'>{item.order_design ? 'نیاز به طراحی داریم' : 'نیازی به طراحی نداریم'}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className='mb-3'>
                                                                <h6 className='mb-3'>تم پروژه :</h6>
                                                                <p className='pr-4'>{item.project_theme}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className='mb-3'>
                                                                <h6 className='mb-3'>زبان :</h6>
                                                                <p className='pr-4'>{item.web_site_language.length === 1 ? item.web_site_language : `${item.web_site_language} ${item.web_site_language.lastIndexOf(item.web_site_language) ? '' : ' - '}`}</p>
                                                                {/*{item.web_site_language.map((row , index)=>(*/}
                                                                {/*    <>*/}
                                                                {/*        <p key={index}>{item.web_site_language.length === 1 ? row : `${row} ${item.web_site_language.lastIndexOf(row)? '' : ' - '}` }</p>*/}
                                                                {/*    </>*/}
                                                                {/*))}*/}
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className='mb-3'>
                                                                <h6 className='mb-3'>شرح فعالیت :</h6>
                                                                <p className='pr-4'>{item.activity_description}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className='mb-3'>
                                                                <Link key={index}
                                                                      to={`/single-projects/${item.id}`}
                                                                      className={'p-2 badge badge-success m-2'}>اطلاعات بیشتر</Link>
                                                                {/*<p>{item.activity_description}</p>*/}
                                                            </div>
                                                        </div>



                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    ))
                                }


                            </div>
                        </div>

                        <FooterMain/>
                    </div>
                    {
                        this.state.isprojectsLoader ?
                            <Loader show /> : ''
                    }
                </div>
            </>
        )
            ;
    }
}

export default projects;
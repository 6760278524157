import React, {Component} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import Login from "./components/pages/login/Login";
import NotFound from "./components/pages/Not Found/NotFound";
import Loader from "./components/pages/Loader/Loader";
import Users from "./components/pages/dashboard/child/main_content/users/Users";
import Projects from "./components/pages/dashboard/child/main_content/projects/Projects";
import ChangePassword from "./components/pages/dashboard/child/main_content/change_password/Change_Password";
import Single from "./components/pages/dashboard/child/main_content/projects/Single";

class App extends Component {
  render() {
    // console.log(localStorage.getItem("brief_tok"))
    return (
        <>

          {
            localStorage.getItem("brief_tok") ?
                  <Switch>
                      {/*OK*/}
                    <Route exact path="/" component={Users}/>
                      {/*OK*/}
                    <Route exact path="/login" render={() => <Redirect to="/users"/>}/>
                      {/*OK*/}
                    <Route exact path="/users" component={Users}/>
                      {/*OK*/}
                    <Route exact path="/projects" component={Projects}/>
                      {/*OK*/}
                    <Route exact path="/password" component={ChangePassword}/>

                    <Route exact path="/single-projects/:id" component={Single}/>
                      {/*OK*/}
                    <Route exact path="*" component={NotFound} />
                  </Switch>
                :
                <Switch>
                    <Route exact path="/login" component={Login}/>
                    <Route exact path="/" component={Login}/>
                    <Route exact path="*" component={NotFound} />
                    <Route exact path="/users" render={() => <Redirect to="/login"/>}/>
                    <Route exact path="/projects" render={() => <Redirect to="/login"/>}/>
                </Switch>
          }
        </>

    );
  }
}

export default App;
import React, {Component} from 'react';
import './projects-styles.css';
import Header_top from "../../header/Header_top";
import RightSidebar from "../../right_sidebar/RightSidebar";
import FooterMain from "../../footer/FooterMain";
import {getData, editedprojects} from "../../../../../../assets/scripts/GeneralFunctions";
import {MAIN_URL, IMAGE_URL_BASE} from "../../../../../../assets/scripts/GeneralVariables";
import Loader from "../../../../Loader/Loader";
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import projects from "./Projects";
import {Link} from "react-router-dom";


class Single extends Component {

    constructor(props) {
        super(props);
        this.state = {
            project: '', items: {},
            isprojectsLoader: false
        }
    }

    async componentDidMount() {
        this.setState({isprojectsLoader: true})
        let project_id = this.props.match.params.id;
        // console.log(project_id)
        let items = await getData(MAIN_URL, `projects/${project_id}`, 'get', {}, true, false);


        if (items.status == 200) {
            this.setState({
                items: items,
                isprojectsLoader: false,
            })
        }
    }

    printDocument(item) {

        const input = document.getElementById('divToPrint');
        html2canvas(input)
            .then((canvas) => {
                // console.log(canvas)
                const imgData = canvas.toDataURL('image/*');
                const pdf = new jsPDF();
                pdf.addImage(imgData, 'base64', 0, 0);
                // pdf.output('dataurlnewwindow');
                pdf.save(`${item}.pdf`);
            })
        ;
    }


    render() {
        let {items} = this.state;
        // console.log(items.project)
        return (
            <>
                <Header_top/>
                <div className='d-flex rtl dv-main-content-size'>
                    <RightSidebar project/>
                    {
                        items.project === undefined || this.state.isprojectsLoader ? <Loader show/> :
                            <div className='dv-main-content dv-scroll'>
                                <div className='container-fluid dv-top-menu-in-page'>
                                    <div className='row'>
                                        <div
                                            className='col-12 col-sm-6 d-flex flex-column flex-md-row align-items-center mb-4'>
                                            <h4 className='mb-3 mb-md-0'>جزئیات پروژه</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className='container-fluid mb-2'>
                                    <div style={{overflowX: 'auto'}}>
                                        <div className="mb-5">
                                            <button onClick={() => this.printDocument(items.project?.company_name)}
                                                    className='btn btn-success btn-sm'>دانلود فایل pdf پروژه
                                            </button>
                                        </div>
                                        <table id="divToPrint" className='dv-table-project mt-5' style={{
                                            width: '50rem',
                                            marginBottom: '4rem',
                                            paddingTop: "2rem",
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                            textAlign : 'right'
                                        }}>
                                            <tbody style={{width: '100%'}}>
                                            <tr>
                                                <td>نام و نام خانوادگی</td>
                                                <td>{items.project?.customer_name}</td>
                                            </tr>
                                            <tr>
                                                <td>شماره تماس</td>
                                                <td>{items.project?.mobile}</td>
                                            </tr>
                                            <tr>
                                                <td>نام مجموعه</td>
                                                <td>{items.project?.company_name}</td>
                                            </tr>
                                            <tr>
                                                <td>موضوع پروژه</td>
                                                <td>{items.project?.company_topic}</td>
                                            </tr>
                                            <tr>
                                                <td>وضعیت مجموعه</td>
                                                <td>{items.project?.company_status}</td>
                                            </tr>
                                            <tr>
                                                <td>اندازه مجموعه</td>
                                                <td>{items.project?.company_size}</td>
                                            </tr>
                                            <tr>
                                                <td>بسترهای پیاده سازی</td>
                                                <td>
                                                    <span>{items.project?.implementation_platform.length === 1 ? items.project?.implementation_platform : `${items.project?.implementation_platform} ${items.project?.implementation_platform.lastIndexOf(items.project?.implementation_platform) ? '' : ' - '}`}</span>
                                                    {/*{items.project?.implementation_platform?.map((row, index) => (*/}
                                                    {/*    <span key={index}>*/}
                                                    {/*        {items.project?.implementation_platform.length === 1 ? row : `${row} ${items.project?.implementation_platform.lastIndexOf(row) ? '' : ' - '}`}*/}
                                                    {/*    </span>*/}
                                                    {/*))}*/}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>حوضه فعالیتی</td>
                                                <td>{items.project?.activity_range}</td>
                                            </tr>
                                            {
                                                items.project?.former_site !== null ?
                                                    <tr>
                                                        <td>آدرس سایت قبلی</td>
                                                        <td><a href={`http://${items.project?.former_site}`}
                                                               target='_blank'>{items.project?.former_site}</a></td>
                                                    </tr> : ''
                                            }

                                            {
                                                items.project?.problems !== null ?
                                                    <tr>
                                                        <td>مشکلات سایت قبلی</td>
                                                        <td>
                                                            <span>{items.project?.problems.length === 1 ? items.project?.problems : `${items.project?.problems} ${items.project?.problems.lastIndexOf(items.project?.problems) ? '' : ' - '}`}</span>
                                                            {/*{items.project?.problems?.map((row, index) => (*/}
                                                            {/*    <span key={index}>*/}
                                                            {/*        {items.project?.problems.length === 1 ? row : `${row} ${items.project?.problems.lastIndexOf(row) ? '' : ' - '}`}*/}
                                                            {/*    </span>*/}
                                                            {/*))}*/}
                                                        </td>
                                                    </tr> : ''
                                            }
                                            <tr>
                                                <td>تم پروژه</td>
                                                <td>{items.project?.project_theme}</td>
                                            </tr>
                                            <tr>
                                                <td>زبان</td>
                                                <td>
                                                    <span>{items.project?.web_site_language.length === 1 ? items.project?.web_site_language : `${items.project?.web_site_language} ${items.project?.web_site_language.lastIndexOf(items.project?.web_site_language) ? '' : ' - '}`}</span>
                                                    {/*{items.project?.web_site_language.map((row, index) => (*/}
                                                    {/*    <span key={index}>*/}
                                                    {/*        {items.project?.web_site_language.length === 1 ? row : `${row} ${items.project?.web_site_language.lastIndexOf(row) ? '' : ' - '}`}*/}
                                                    {/*    </span>*/}
                                                    {/*))}*/}
                                                </td>
                                            </tr>
                                            {
                                                items.project?.instagram !== null ?
                                                    <tr>
                                                        <td>آدرس پیج اینستاگرام</td>
                                                        <td>{items.project?.instagram.indexOf('instagram.com/')!== -1 ? <a href={items.project?.instagram}>{items.project?.instagram}</a> : <a href={`https://instagram.com/${items.project?.instagram}`} target='_blank'>{items.project?.instagram}</a> }</td>
                                                    </tr> : ''
                                            }
                                            {
                                                items.project?.linkedin !== null ?
                                                    <tr>
                                                        <td>آدرس لینکدین</td>
                                                        <td>{items.project?.instagram.indexOf('linkdin.com/')!== -1 ? <a href={items.project?.linkedin}>{items.project?.instagram}</a> : <a href={`https://linkdin.com/in/${items.project?.linkedin}`} target='_blank'>{items.project?.linkedin}</a> }</td>
                                                    </tr> : ''
                                            }
                                            {
                                                items.project?.facebook !== null ?
                                                    <tr>
                                                        <td>آدرس فیسبوک</td>
                                                        <td>{items.project?.facebook.indexOf('facebook.com/')!== -1 ? <a href={items.project?.facebook}>{items.project?.instagram}</a> : <a href={`https://facebook.com/${items.project?.facebook}`} target='_blank'>{items.project?.facebook}</a> }</td>
                                                    </tr> : ''
                                            }
                                            {
                                                items.project?.twitter !== null ?
                                                    <tr>
                                                        <td>آدرس توییتر</td>
                                                        <td>{items.project?.twitter.indexOf('twitter.com/')!== -1 ? <a href={items.project?.twitter}>{items.project?.twitter}</a> : <a href={`https://twitter.com/${items.project?.twitter}`} target='_blank'>{items.project?.twitter}</a> }</td>
                                                    </tr> : ''
                                            }
                                            <tr>
                                                <td>وضعیت سفارش لوگو</td>
                                                <td>{items.project?.order_design ? 'نیاز به طراحی داریم' : 'نیازی به طراحی نداریم'}</td>
                                            </tr>
                                            {
                                                items.project?.logo_image !== null ?
                                                    <tr>
                                                        <td className='mb-2'>لوگو پیشنهادی</td>
                                                        <td>
                                                            <img src={IMAGE_URL_BASE + items.project?.logo_image}
                                                                 className='img-fluid' width={100}
                                                                 height={100} alt='لوگو پیشنهادی'/>
                                                        </td>
                                                    </tr> : ''
                                            }

                                            {/*{*/}
                                            {/*    items.project?.desired_sites !== null ?*/}
                                            {/*        <tr>*/}
                                            {/*            <td className='mb-2'>آدرس سایت های پیشنهادی*/}
                                            {/*                :*/}
                                            {/*            </td>*/}

                                            {/*            {items.project?.desired_sites?.map((row, index) => (*/}
                                            {/*                <td>*/}
                                            {/*                    <a key={index} href={`http://${row.url}`}*/}
                                            {/*                       target='_blank'>{row.url}</a>*/}
                                            {/*                </td>*/}
                                            {/*            ))}*/}
                                            {/*        </tr>*/}
                                            {/*        : ''*/}
                                            {/*}*/}
                                            <tr>
                                                <td>شرح فعالیت</td>
                                                <td>{items.project?.activity_description}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <FooterMain/>
                            </div>
                    }
                    {/*{*/}
                    {/*    this.state.isprojectsLoader ?*/}
                    {/*        <Loader show/> : ''*/}
                    {/*}*/}
                </div>
            </>
        );
    }
}

export default Single;